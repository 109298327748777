const Cookie = {
  getCookie: (cname: any) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  erase: (name: string, path: string = "/") => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;
  },
  createTapAffiliateClick: (cookieValue: any) => {
    const name = "ta_click";
    const value = cookieValue;
    const expirationDays = 90;
    const domain = import.meta.env.DEV ? "localhost" : ".flixlatino.com";
    const path = "/";

    const expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() + expirationDays * 24 * 60 * 60 * 1000
    );
    const expires = "expires=" + expirationDate.toUTCString();

    Cookie.createCookie(name, value, expires, path, domain);
  },

  createTapAffiliateCookie: (cookieValue: any) => {
    const name = "ta_ref";
    const value = cookieValue;
    const expirationDays = 90;
    const domain = import.meta.env.DEV ? "localhost" : ".flixlatino.com";
    const path = "/";

    const expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() + expirationDays * 24 * 60 * 60 * 1000
    );
    const expires = "expires=" + expirationDate.toUTCString();

    Cookie.createCookie(name, value, expires, path, domain);
  },

  createCookie: (
    name: string,
    value: any,
    expires: string,
    path: string,
    domain: string
  ) => {
    document.cookie =
      name +
      "=" +
      value +
      ";" +
      expires +
      ";path=" +
      path +
      ";domain=" +
      domain +
      ";Secure";
  },
};

export default Cookie;
